export default {
  list: {
    title: 'Ticket List',
    columns: {
      no: 'No',
      bookingCode: 'Booking Code',
      identifyNumber: 'Identify Number',
      customerName: 'Customer',
      bookingInfos: 'Booking Infos',
      totalPrice: 'Total Price',
      createdAt: 'Created At',
      createdBy: 'Created By',
      status: 'Status',
      actions: 'Actions',
    },
  },
  filter: {
    statusPlaceholder: 'Select status',
    tripDatePlaceholder: 'Trip date',
    bookDatePlaceholder: 'Book date',
    bookingCodePlaceholder: 'Booking code',
    searchTextPlaceholder: 'Search',
    clearFilter: 'Clear filters',
  },
  status: {
    CANCEL: 'Cancel',
    EXPIRED: 'Expired',
    HOLD: 'Hold',
    PAID: 'Paid',
    PROCESSING: 'Processing',
    ALL: 'All',
  },
}
