export default {
  list: {
    title: 'Danh sách đặt chỗ tàu',
    columns: {
      no: 'STT',
      bookingCode: 'Mã đặt chỗ',
      identifyNumber: 'Mã vé',
      customerName: 'Khách hàng',
      bookingInfos: 'Thông tin vé',
      totalPrice: 'Giá',
      createdAt: 'Ngày đặt',
      createdBy: 'Người đặt',
      status: 'Trạng thái',
      actions: 'Thao tác',
    },
  },
  filter: {
    statusPlaceholder: 'Chọn trạng thái',
    tripDatePlaceholder: 'Ngày khởi hành',
    bookDatePlaceholder: 'Ngày đặt vé',
    bookingCodePlaceholder: 'Mã đặt chỗ',
    searchTextPlaceholder: 'Tìm kiếm',
    clearFilter: 'Xoá bộ lọc',
  },
  status: {
    CANCEL: 'Đã hủy',
    EXPIRED: 'Quá hạn',
    HOLD: 'Giữ chỗ',
    PAID: 'Đã thanh toán',
    PROCESSING: 'Đang thực hiện',
    ALL: 'Tất cả',
  },
}
