import service from './service'

export default {
  getCategory() {
    return service.get('/trains/systems/category')
  },
  getSetting() {
    return service.get('/trains/systems/setting')
  },
  searchTrain(payload) {
    return service.post('/trains/tickets/search', payload)
  },
  fetchSeatByCoach(payload) {
    return service.post('/trains/tickets/seat-by-coach', payload)
  },
  pickTicket(payload) {
    return service.post('/trains/tickets/pick', payload)
  },
  dropTicket(payload) {
    return service.post('/trains/tickets/drop', payload)
  },
  saveBookings(payload) {
    return service.post('/trains/tickets/save', payload)
  },

  // BOOKING LIST
  fetchBookingList(payload) {
    return service.post('/trains/bookings', payload)
  },

  // BOOKING DETAIL
  getBookingById(bookingId) {
    return service.get(`/trains/bookings/${bookingId}`)
  },

}
